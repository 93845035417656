<template>
	<div class="grid caja-center"  style="padding: 1em 1em 1em 1em;">

		<div class="col-12 md:col-4">
			<div class="card p-fluid">
                <img src="logo.png" class="w-12 h-12 mx-auto" alt="">
                <div class="grid">
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://api.whatsapp.com/send?phone=+584147974624&text=Fisiomarvzla')">
                            <h2><i class="pi pi-whatsapp" style="font-size: 1.5rem"></i> WhatsApp</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('http://fisiomarvzla.com')">
                            <h2><i class="pi pi-globe" style="font-size: 1.5rem"></i> Página WEB</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://www.google.com/maps/place/Fisiomar/@10.1836728,-64.69056,15z/data=!4m2!3m1!1s0x0:0x9de8dab1acfc97e9?sa=X&ved=2ahUKEwidwci57ar-AhWOZjABHdv3BWgQ_BJ6BAhaEAk')">
                            <h2><i class="pi pi-map-marker" style="font-size: 1.5rem"></i> Dirección</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://instagram.com/fisiomarvzla')">
                            <h2><i class="pi pi-instagram" style="font-size: 1.5rem"></i> Instagram</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://tiktok.com/@fisiomarvzla')">
                            <h2><i class="pi pi-instagram" style="font-size: 1.5rem"></i> TikTok</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://twitter.com/fisiomarvzla')">
                            <h2><i class="pi pi-twitter" style="font-size: 1.5rem"></i> Twitter</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://www.facebook.com/fisiomarvzla')">
                            <h2><i class="pi pi-facebook" style="font-size: 1.5rem"></i> Facebook</h2> 
                        </Button>
                    </div>
                    <div class="col-12 md:col-12">
                        <Button style="padding: 1em 1em 0em 1em; justify-content: center;" @click="IR('https://youtube.com/@fisiomarvzla4816')">
                            <h2><i class="pi pi-youtube" style="font-size: 1.5rem"></i> Youtube</h2> 
                        </Button>
                    </div>


                </div>
			</div>
		</div>
    </div>
</template>

<script>

export default {
	props: {
		navegar: {
			type: String,
			default: null
		}
	},
	components: {
	},
	data() {
		return {
		}
	},
	created() {

	},
	watch: {
	},
    methods: {
        IR(url){
            window.location.href = url;
        }
        
    },
    computed: {
    },
    
    
}
</script>

<style>
    .caja-center{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>